// Copyright 1999-2018. Plesk International GmbH. All rights reserved.

if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector;
}

import Firehose from 'aws-sdk/clients/firehose';

const link = document.createElement('a');
const filterPleskUrl = url => {
    link.href = url;

    const { pathname, search, hash } = link;

    const sensitiveUrls = [
        '^/smb/file-manager/*',
        '^/smb/backup/*',
        '^/admin/backup/*',
        '/hosting/web-directories/',
    ];

    if (sensitiveUrls.some(pattern => pathname.match(pattern, 'i'))) {
        return pathname;
    }

    const sensitiveParams = [
        /\[searchText]=[^&]*/g,
    ];
    const safeSearch = sensitiveParams.reduce((result, pattern) => result.replace(pattern, ''), search);

    return pathname + safeSearch + hash;
};

const filterExternalUrl = url => {
    const allowedUrls = [
        '^https?://(www.)?go.plesk.com*',
        '^https?://(www.)?docs.plesk.com*',
        '^https?://(www.)?plesk.com*',
    ];

    if (allowedUrls.some(pattern => url.match(pattern, 'i'))) {
        return url.split('?')[0];
    }
    return null;
};

export const getUrl = href => {
    if (href && href.match('^(?:[a-z]+:)?//', 'i')) {
        return filterExternalUrl(href);
    }

    return filterPleskUrl(href ? href : window.location.href);
};

const getElementCSSSelector = el => {
    if (!el || !el.localName) {
        return null;
    }
    let label = el.localName.toLowerCase();
    if (el.id) {
        label += `#${el.id}`;
    }
    if (el.classList) {
        for (let i = 0, len = el.classList.length; i < len; ++i) {
            label += `.${el.classList[i]}`;
        }
    }
    return label;
};

const getElementCSSPath = (el, depth) => {
    const paths = [];
    for (let i = 0; el && el.nodeType === Node.ELEMENT_NODE && i < depth; el = el.parentNode, i++) {
        paths.splice(0, 0, getElementCSSSelector(el));
    }
    return paths.length ? paths.join(' ') : null;
};

const getElement = event => {
    let { target } = event;
    const { currentTarget, type } = event;

    if (currentTarget
        && currentTarget.tagName
        && (type === 'load'
            || type === 'error'
            || (type === 'click'
                && currentTarget.tagName.toLowerCase() === 'input'
                && currentTarget.type === 'radio'
            )
        )
    ) {
        target = currentTarget;
    }

    return target.nodeType === Node.TEXT_NODE ? target.parentNode : target;
};

const findElement = (event, selector, matchClasses) => {
    let element = getElement(event);

    if (!selector && !matchClasses) {
        return element;
    }

    const isMatches = el => matchClasses && matchClasses.some(cls => (new RegExp(`\\b${cls}\\b`, 'i')).test(el.getAttribute('class')));

    while (element) {
        if (element.nodeType === Node.ELEMENT_NODE
            && ((!matchClasses && element.matches(selector)) || isMatches(element))
        ) {
            return element;
        }
        element = element.parentNode;
    }

    return null;
};

export const prepareNodeData = (el, textEl, config) => {
    const data = {};
    const PARENT_DEPTH_LIMIT = 5;
    data.css = getElementCSSPath(el, PARENT_DEPTH_LIMIT);
    if (el.id) {
        data.id = el.id;
    }
    if (config && config.attributes) {
        config.attributes.forEach(attr => {
            if (!el.hasAttribute(attr)) {
                return;
            }
            const value = (attr === 'href') ? getUrl(el.getAttribute(attr)) : el.getAttribute(attr);
            if (value) {
                data[attr] = value;
            }
        });
    }
    ['id', 'type', 'action', ...[config && config.dataset ? config.dataset : []]].forEach(param => {
        if (!(param in el.dataset)) {
            return;
        }
        if (!('dataset' in data)) {
            data.dataset = {};
        }
        data.dataset[param] = el.dataset[param];
    });
    return data;
};

const getParents = target => {
    if (!target || !target.parentElement) {
        return [];
    }

    let parent = target;
    const parents = [];
    while (parent) {
        parents.push(parent.dataset.type);
        parent = parent.parentElement.closest('[data-type]');
    }

    if (parents.length > 1) {
        return parents.slice(1);
    }

    return [];
};

const preparePostData = (action, target) => {
    const data = {};

    if (action.post && target) {
        if (action.post.self) {
            action.post.self.forEach(function (attr) {
                if (attr === 'value') {
                    return;
                }
                const value = target.getAttribute(attr);
                if (value) {
                    data[attr] = value;
                }
            });
        }
        if (action.post.selfText) {
            data.text = target.innerText;
        }
    }

    if (action.data) {
        Object.keys(action.data).forEach(function (key) {
            data[key] = action.data[key];
        });
    }

    const parents = getParents(target);
    if (parents.length > 0) {
        data.parents = parents;
    }

    return data;
};

/**
 * Firehose instance
 */
let firehose;
let config;
let initialized = false;
let patches = {};

export const request = (action, target, result) => {
    const parameters = {
        timestamp: (new Date()).toISOString(),
        instanceId: config.instanceId,
        accountLevel: config.accountLevel,
        accountId: config.accountId,
        sessionId: config.sessionId,
        path: action.url || getUrl(),
        action: action.name || null,
        result: result || null,
    };

    const data = preparePostData(action, target);
    if (config.parentId) {
        data.parentId = config.parentId;
    }
    if (Object.keys(data).length) {
        parameters.additionalData = JSON.stringify(data);
    }
    if (typeof config.logger === 'function') {
        config.logger(parameters);
    }
    if (!config.firehose) {
        return;
    }
    if (!firehose) {
        firehose = new Firehose(config.firehose);
    }

    const values = Object.keys(parameters).map(key => parameters[key]);
    firehose.putRecord({
        DeliveryStreamName: config.firehose.stream,
        Record: {
            Data: `${values.join('|')}\n`,
        },
    }, () => {
        // empty callback
    });
};

let watchers = {
    contentLoad(contentConfig, expect, action) {
        if (document.readyState === 'loading') {
            document.addEventListener('DOMContentLoaded', function (event) {
                if (config.extensions) {
                    if (!action.data) {
                        action.data = {};
                    }
                    action.data.extesions = config.extensions;
                }
                request(action, event.target);
            }, true);
        } else {
            request(action, document);
        }
    },

    click: ({ elements }, expect, action, eventName) => {
        document.addEventListener(eventName, function (event) {
            if (event.uatHandled) {
                return;
            }
            for (let i = 0; i < elements.length; i++) {
                let el;
                let { selector } = elements[i];
                const { matchClasses } = elements[i];
                if (selector) {
                    selector = Array.isArray(selector) ? selector : [selector];
                    for (let j = 0; j < selector.length && !el; j++) {
                        el = findElement(event, selector[j], matchClasses);
                    }
                }
                if (el) {
                    event.uatHandled = true;
                    request({
                        ...action,
                        name: (el.dataset.action || action.name).toUpperCase(),
                        data: prepareNodeData(el, event.target, elements[i]),
                    }, el);
                    break;
                }
            }
        }, true);
    },
};

let actions = [
    {
        expects: [{
            contentLoad: {},
        }],
    },
    {
        name: 'CLICK',
        expects: [{
            click: {
                elements: [
                    {
                        selector: '[data-action]',
                    },
                    {
                        selector: 'a',
                        attributes: ['href'],
                    },
                    {
                        selector: 'button',
                    },
                    {
                        selector: ['span', 'div'],
                        matchClasses: ['commonButton', 'btn', 'link', 'hint', 'button', 'control'],
                    },
                ],
            },
        }],
    },
];

const patchUI = () => {
    Object.keys(patches).forEach(name => {
        patches[name]();
    });
};

const startTracking = () => {
    actions.forEach(function (action) {
        action.expects.forEach(function (expect) {
            Object.keys(expect).forEach(function (event) {
                watchers[event] && watchers[event](expect[event], expect, action, event);
            });
        });
    });
};

const UAT = {
    init(initConfig) {
        if (!initConfig || initialized) {
            return;
        }

        config = initConfig;
        patchUI();
        startTracking();
        initialized = true;
    },

    setPatches(fn) {
        patches = fn(patches);
    },

    setActions(fn) {
        actions = fn(actions);
    },

    setWatchers(fn) {
        watchers = fn(watchers);
    },

    setLogger(logger) {
        config.logger = logger;
    },

    dispatchAction(action, data) {
        if (!initialized) {
            return;
        }

        request({ name: action, url: getUrl(), data });
    },

    getConfig() {
        return config;
    },
};

export default UAT;
