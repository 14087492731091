// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { Component } from './component';
import render from './render';
import createElement from './createElement';
import escapeHtml from './escapeHtml';

export class ImageSelector extends Component {
    _initConfiguration(config) {
        super._initConfiguration(config);
        const list = this._getConfigParam('list', {});
        this._list = Array.isArray(list) ? $H() : $H(list);
        const captions = this._getConfigParam('captions', {});
        this._captions = Array.isArray(captions) ? $H() : $H(captions);
        this._name = this._getConfigParam('name', '');
        this._value = this._getConfigParam('value', null);

        this._currentId = `${this._id}-current`;
        this._listId = `${this._id}-list`;
    }

    _initComponentElement() {
        super._initComponentElement();

        this._updateComponentElement(
            `<div class="${this._cls}-area">` +
                `<div id="${this._currentId}" class="${this._cls}-current">` +
                    '<img/>' +
                    `<p>${escapeHtml(this._captions.get('selectedImage'))}</p>` +
                '</div>' +
                `<div id="${this._listId}" class="${this._cls}-select clearfix"></div>` +
            '</div>'
        );

        render(this._componentElement, createElement('input', {
            id: `${this._id}-value`,
            name: this._name,
            type: 'hidden',
            value: null,
            onrender: el => {
                this._valueElement = el;
            },
        }));
    }

    _addEvents() {
        this._list.each(({ key, value }) => {
            this._addImage(key, value);
        });

        this.set(this._value);
    }

    _addImage(value, { url, title }) {
        this._componentElement.querySelectorAll(`#${this._listId}`).forEach(target => {
            render(target, createElement('img', {
                src: url,
                alt: title,
                onclick: event => {
                    this._onImageClick(event, value);
                },
            }));
        });
    }

    _onImageClick(event, value) {
        this.set(value);
    }

    /**
     * Update component value.
     * @param {String} value
     */
    set(value) {
        this._valueElement.value = value;
        const image = this._list.get(value);
        this._componentElement.querySelectorAll(`#${this._currentId} img`).forEach(target => {
            if (image) {
                target.src = image.url;
                target.alt = image.title;
                target.style.display = '';
            } else {
                target.style.display = 'none';
            }
        });
    }
}
