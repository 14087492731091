// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

import { Tooltip } from 'jsw';
import { createElement, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Translate, Dropdown, Menu, MenuItem, Button, Icon, isRtl, Text } from '@plesk/ui-library';
import { useQuery, gql } from '@apollo/client';
import { useMediaQuery } from 'common/hooks';
import NotificationCenter from './NotificationCenter';
import { useConfigContext } from '../ConfigContext';
import { useDataContext } from '../DataContext';
import Link from '../Link';

/* global getHelpUrl, SetContext, GetContext, GetHelpModule */

const SubscriptionSwitcher = ({ items, title, activeModule, description }) => {
    const { data: { returnUrl } } = useDataContext();
    const isHideText = useMediaQuery('(max-width: 767px)');

    const button = (
        <Button
            data-type="subscription-switcher"
            icon="globe"
            tooltip={description}
            caret={items.length > 1 && !isHideText}
            tabIndex={items.length > 1 ? null : -1}
            style={items.length > 1 ? null : { pointerEvents: 'none' }}
            ghost
        >
            {isHideText ? null : <Text truncate>{title}</Text>}
        </Button>
    );

    if (items.length === 1) {
        return button;
    }

    return (
        <Dropdown
            menu={(
                <Menu data-type="subscription-switcher-menu">
                    {items.map(({ title, href, disabled, description }) => (
                        <MenuItem
                            key={href}
                            component="a"
                            href={disabled ? null : `${href}?returnUrl=${returnUrl}&changeId=1&activeModule=${activeModule}`}
                            disabled={disabled}
                            tooltip={description}
                        >
                            {title}
                        </MenuItem>
                    ))}
                </Menu>
            )}
            menuPlacement="bottom-end"
        >
            {button}
        </Dropdown>
    );
};

SubscriptionSwitcher.propTypes = {
    items: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    activeModule: PropTypes.string,
};

SubscriptionSwitcher.defaultProps = {
    activeModule: null,
};

const AccountMenu = ({ items, title, description }) => {
    const isHideText = useMediaQuery('(max-width: 767px)');

    return (
        <Dropdown
            menu={(
                <Menu>
                    {items.map(({ title, href, icon, dataType }) => (
                        <MenuItem
                            key={href}
                            component="a"
                            icon={icon}
                            href={href}
                            data-type={dataType}
                        >
                            {title}
                        </MenuItem>
                    ))}
                </Menu>
            )}
            menuPlacement="bottom-end"
        >
            <Button id="account-menu" icon="user" tooltip={description} ghost caret={!isHideText}>
                {isHideText ? null : <Text truncate>{title}</Text>}
            </Button>
        </Dropdown>
    );
};

AccountMenu.propTypes = {
    items: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

const renderTooltips = () => {
    document.querySelectorAll('.main-header-navbar-nav__item').forEach(item => {
        if (item.dataset.description) {
            Tooltip.init(item, {
                text: item.dataset.description,
            });
        }
    });
};

const HelpMenu = ({
    items,
    isSmb,
    isClassicMode,
    customHelpUrl,
    helpContext,
}) => {
    items.forEach(item => {
        if ('help' === item.value) {
            if (isSmb && !isClassicMode) {
                if (customHelpUrl) {
                    item.href = customHelpUrl;
                } else {
                    /* eslint-disable new-cap */
                    if (helpContext) {
                        SetContext(helpContext);
                    }
                    if (GetContext()) {
                        item.href = `${item.href}/plesk-context/${GetContext()}`;
                    }
                    if (GetHelpModule()) {
                        item.href = `${item.href}/plesk-module/${GetHelpModule()}`;
                    }
                    /* eslint-enable new-cap */
                }

                return;
            }

            if (!item.href) {
                item.href = getHelpUrl();
            }
        }
    });

    return (
        <Dropdown
            menu={(
                <Menu>
                    {items.map(({ title, icon, href, target, dataType }) => (
                        <MenuItem
                            key={href}
                            component={Link}
                            icon={icon}
                            data-type={dataType}
                            to={href}
                            target={target}
                            rel={target ? 'noopener noreferrer' : null}
                        >
                            {title}
                        </MenuItem>
                    ))}
                </Menu>
            )}
            menuPlacement="bottom-end"
        >
            <Button id="help-menu" ghost icon="question-mark-circle" />
        </Dropdown>
    );
};

HelpMenu.propTypes = {
    items: PropTypes.array.isRequired,
    isSmb: PropTypes.bool,
    isClassicMode: PropTypes.bool.isRequired,
    customHelpUrl: PropTypes.string,
    helpContext: PropTypes.string,
};

HelpMenu.defaultProps = {
    isSmb: false,
    helpContext: undefined,
    customHelpUrl: undefined,
};

const MainHeaderNavbar = ({
    isSmb,
    isClassicMode,
    backToAdminLink,
    accountMenu,
    subscriptionSwitcher,
    notificationCenter,
    headerCustomButtons,
    helpContext,
    customHelpUrl,
    helpMenu,
    isCustomLogo,
}) => {
    const isHideBackToAdminText = useMediaQuery('(max-width: 1310px)');
    const isHideLogo = useMediaQuery('(max-width: 1140px)');
    const config = useConfigContext();
    const { data: { viewer: user } = {} } = useQuery(gql`
        {
            viewer {
                type
            }
        }
    `);

    useEffect(() => {
        renderTooltips();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="main-header-navbar">
            <ul className="main-header-navbar-nav">
                {backToAdminLink ? (
                    <li className="top-back">
                        <Button
                            component="a"
                            icon={<Icon name="chevron-left" flipHorizontal={isRtl()} />}
                            href={backToAdminLink}
                            ghost
                        >
                            {isHideBackToAdminText ? null : <Translate content="shortcuts.backToAdmin" />}
                        </Button>
                    </li>
                ) : null}

                {accountMenu ? (
                    <li className="top-user">
                        <AccountMenu {...accountMenu} />
                    </li>
                ) : null}

                {accountMenu && subscriptionSwitcher ? (
                    <li className="top-webspace">
                        <SubscriptionSwitcher {...subscriptionSwitcher} />
                    </li>
                ) : null}

                {user?.type === 'ADMIN' && config.notification.panel.enabled ? (
                    <li className="top-notification-center">
                        <NotificationCenter {...notificationCenter} />
                    </li>
                ) : null}

                {headerCustomButtons.map(({ id, link, target, icon, title, description }) => (
                    <li
                        key={id}
                        id={`extNavHeader-${id}`}
                        className={classNames('main-header-navbar-nav__item', `main-header-navbar-nav__item--${id}`)}
                        data-description={description}
                    >
                        <Button
                            component="a"
                            href={link}
                            target={target}
                            rel={target ? 'noopener noreferrer' : null}
                            icon={<Icon src={id === 'advisor-1' ? require('icons/16/plesk/top-advisor.svg') : icon} alt={title} />}
                            ghost
                        />
                    </li>
                ))}

                {helpMenu ? (
                    <li className="top-help">
                        <HelpMenu {...helpMenu} isSmb={isSmb} isClassicMode={isClassicMode} customHelpUrl={customHelpUrl} helpContext={helpContext} />
                    </li>
                ) : null}

                {isCustomLogo && !isHideLogo ? (
                    <li className="top-logo">
                        <a href="http://www.plesk.com" target="_blank" rel="noopener noreferrer">
                            <img src={require('images/logos/powered-logo.png')} alt="" />
                        </a>
                    </li>
                ) : null}
            </ul>
        </div>
    );
};

MainHeaderNavbar.propTypes = {
    isSmb: PropTypes.bool,
    isClassicMode: PropTypes.bool.isRequired,
    backToAdminLink: PropTypes.string,
    accountMenu: PropTypes.shape({
        items: PropTypes.array,
        title: PropTypes.string,
        description: PropTypes.string,
    }),
    subscriptionSwitcher: PropTypes.shape({
        items: PropTypes.array,
        title: PropTypes.string,
        returnUrl: PropTypes.string,
        activeModule: PropTypes.string,
        description: PropTypes.string,
    }),
    notificationCenter: PropTypes.shape({
        locale: PropTypes.object,
        wsEnabled: PropTypes.bool,
    }),
    headerCustomButtons: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        link: PropTypes.string,
        target: PropTypes.string,
        icon: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
    })).isRequired,
    helpContext: PropTypes.string,
    customHelpUrl: PropTypes.string,
    helpMenu: PropTypes.shape({
        items: PropTypes.array,
        title: PropTypes.string,
    }),
    isCustomLogo: PropTypes.bool.isRequired,
};

MainHeaderNavbar.defaultProps = {
    isSmb: false,
    backToAdminLink: undefined,
    accountMenu: undefined,
    subscriptionSwitcher: undefined,
    notificationCenter: undefined,
    helpContext: undefined,
    customHelpUrl: undefined,
    helpMenu: undefined,
};

export default MainHeaderNavbar;
