// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { NOTIFICATION_INTENTS } from './PropTypes';
import getIntentByNotification from './getIntentByNotification';

export default notifications => {
    const intents = notifications.map(getIntentByNotification);

    switch (true) {
        case intents.includes(NOTIFICATION_INTENTS.DANGER):
            return NOTIFICATION_INTENTS.DANGER;
        case intents.includes(NOTIFICATION_INTENTS.WARNING):
            return NOTIFICATION_INTENTS.WARNING;
    }

    return NOTIFICATION_INTENTS.INACTIVE;
};
