// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

import { createElement, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Info from './Info';
import Preferences, { OverlayAdapter } from './Preferences';
import { loadPreferences, savePreferences, hasPreferences } from 'common/gdpr-storage';
import usePreferencesToggle from './usePreferencesToggle';
import { api } from 'jsw';

const CookiePolicy = ({ layoutType, isInfoEnabled }) => {
    const [isInfoVisible, setInfoVisible] = useState(() => !hasPreferences());
    const [isPreferencesVisible, setPreferencesVisible] = usePreferencesToggle();
    const [preferences, setPreferences] = useState(loadPreferences);

    const handleSave = nextPreferences => {
        setInfoVisible(false);
        setPreferences(nextPreferences);
        savePreferences(nextPreferences);
        api.post('/admin/gdpr/update', nextPreferences);
    };

    return (
        <Fragment>
            {isInfoEnabled && isInfoVisible && (
                <Info
                    onSave={handleSave}
                    onPreferencesToggle={setPreferencesVisible}
                    contentAlignment={layoutType === 'simplified' ? 'center' : undefined}
                />
            )}
            <Preferences
                isOpen={isPreferencesVisible}
                preferences={preferences}
                onSave={handleSave}
                onToggle={setPreferencesVisible}
                adapter={layoutType === 'simplified' ? OverlayAdapter : undefined}
            />
        </Fragment>
    );
};

CookiePolicy.propTypes = {
    isInfoEnabled: PropTypes.bool.isRequired,
    layoutType: PropTypes.oneOf(['simplified']),
};

CookiePolicy.defaultProps = {
    layoutType: undefined,
};

export default CookiePolicy;
