// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { createContext, useContext } from 'react';

const ConfigContext = createContext({
    product: {
        privacyPolicyUrl: undefined,
    },
    webSocket: {
        enabled: true,
    },
    license: {
        fileUpload: false,
    },
    notification: {
        panel: {
            enabled: true,
        },
    },
    search: {
        limit: 10,
    },
});

export default ConfigContext;
export const useConfigContext = () => useContext(ConfigContext);
