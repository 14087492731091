// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

export default () => {
    const contentElement = document.getElementById('main');
    if (!contentElement) {
        return;
    }
    ['.msg-error', '.msg-info', '.msg-warning'].forEach(msgClass => {
        contentElement.querySelectorAll(msgClass).forEach(messageElement => {
            messageElement.parentNode.removeChild(messageElement);
        });
    });
};
