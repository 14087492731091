// Copyright 1999-2017. Plesk International GmbH. All rights reserved.

const { style } = document.documentElement;
const domPrefixes = ['Webkit', 'Moz', 'O', 'ms'];

const checkProp = prop => {
    if ('undefined' !== typeof style[prop]) {
        return true;
    }

    prop = prop.charAt(0).toUpperCase() + prop.slice(1);
    for (let i = 0; i < domPrefixes.length; i++) {
        if ('undefined' !== typeof style[domPrefixes[i] + prop]) {
            return true;
        }
    }

    return false;
};

/**
 * @class BrowserFeatures
 * @singleton
 */
export const BrowserFeatures = (() => ({
    /**
     * @property {Boolean} transition
     */
    transition: checkProp('transition'),
}))();
