// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { PopupForm } from './popup-form';
import { getComponent } from './component';
import { redirect } from './form-redirect';
import prepareUrl from './prepareUrl';
import render from './render';
import api from './api';

export const ConfirmationPopupManager = {};
ConfirmationPopupManager.PopupForm = class extends PopupForm {
    _initConfiguration(config) {
        this._sendButtonTitle = '';

        super._initConfiguration({
            singleRowButtons: true,
            ...config,
        });

        this._prepareUrl = this._getConfigParam('prepareUrl', '');
        this._handlerUrl = this._getConfigParam('handlerUrl', '');
        this._ids = this._getConfigParam('ids', '');
        this._formListItemsAreaId = `${this._id}-form-list-items`;
        this._formListAreaId = `${this._id}-form-list`;
        this._formBoxAreaId = `${this._id}-form-box`;
        this._sendButtonId = this._getConfigParam('sendButtonId', 'btn-send');
        this._cancelButtonId = this._getConfigParam('cancelButtonId', 'btn-cancel');
        this._formDescriptionId = `${this._id}-form-desc`;
        this._longtask = this._getConfigParam('longtask', false);
        this._autoload = this._getConfigParam('autoload', true);
    }

    render() {
        super.render();

        this.setBoxType('form-box');
        this._setTitle();

        const formHtml = (
            `<form method="post" action="" enctype="application/x-www-form-urlencoded" id="${this._id}-form">` +
                `<div class="box" id="${this._formBoxAreaId}"></div>` +
                `<div class="list" id="${this._formListAreaId}"></div>` +
            '</form>'
        );

        document.getElementById(this._contentAreaId).innerHTML = (
            this._getHeadDescription() +
            formHtml +
            this._getBottomDescription()
        );

        const form = document.getElementById(`${this._id}-form`);
        form._formSubmit = form.submit;
        form.submit = this._onSubmit.bind(this);
        form.addEventListener('submit', this._onSubmitEvent.bind(this));

        render(document.getElementById(this._formBoxAreaId), `<div class="ajax-loading">${this.lmsg('loading')}</div>`);

        this._addButtons();

        if (this._autoload) {
            this._renderPreparePopup();
        }
    }

    reload() {
        document.getElementById(this._formListAreaId).innerHTML = '';
        document.getElementById(this._formBoxAreaId).innerHTML = `<div class="ajax-loading">${this.lmsg('loading')}</div>`;
        this._preparePopup();
    }

    _addButtons() {
        this.addRightButton(this.lmsg('buttonOk'), this._onOkClick, true, true, { id: this._sendButtonId });
        this.addRightButton(this.lmsg('buttonCancel'), this._onCancelClick, false, false, { id: this._cancelButtonId });
    }

    _setTitle() {
        this.setTitle(this.lmsg('title'));
    }

    _getHeadDescription() {
        return '';
    }

    _getBottomDescription() {
        return `<p id="${this._formDescriptionId}">${this.lmsg('description')}</p>`;
    }

    _onException(exception) {
        this._addErrorMessage(`Internal error: ${exception}`);
        this.enable();
    }

    _onOkClick(e) {
        this._onSubmit(e);
    }

    _onCancelClick() {
        this.hide();
    }

    _preparePopup() {
        api.post(prepareUrl(this._prepareUrl), { ids: this._ids })
            .then(this._onSuccessPreparePopup.bind(this))
            .catch(this._onException.bind(this));
    }

    _renderPreparePopup() {
        this._preparePopup();
    }

    _onSubmitEvent(event) {
        this._onSubmit();
        event.preventDefault();
        return false;
    }

    _onSuccessResponse(response) {
        this.hide();
        redirect(response.redirect);
    }

    _onSuccess(transport) {
        this._clearMessages();
        try {
            const response = JSON.parse(transport.responseText);
            if ('success' === response.status) {
                this._onSuccessResponse(response);
            } else {
                if (response.redirect) {
                    redirect(response.redirect);
                    return;
                }
                this._addErrorMessage(response.message);
                this.enable();
            }
        } catch (e) {
            this._addErrorMessage(e.message);
            this._addErrorMessage(
                `Internal error: ${transport.responseText}`
            );
            this.enable();
        }
    }

    disable() {
        const sendButton = document.getElementById(this._sendButtonId);
        if (sendButton) {
            sendButton.disabled = true;
            sendButton.classList.add('disabled');
            if (!this._sendButtonTitle) {
                this._sendButtonTitle = sendButton.innerHTML;
            }
            sendButton.innerHTML = `<span class="wait">${this.lmsg('loading')}</span>`;
        }

        const cancelButton = document.getElementById(this._cancelButtonId);
        if (cancelButton) {
            cancelButton.disabled = true;
            cancelButton.classList.add('disabled');
        }
    }

    enable() {
        const sendButton = document.getElementById(this._sendButtonId);
        if (sendButton) {
            sendButton.disabled = false;
            sendButton.innerHTML = this._sendButtonTitle;
            sendButton.classList.remove('disabled');
        }

        const cancelButton = document.getElementById(this._cancelButtonId);
        if (cancelButton) {
            cancelButton.disabled = false;
            cancelButton.classList.remove('disabled');
        }
    }

    _onSuccessPreparePopup() {
        // should be implemented in child classes
        return true;
    }

    _getLoadingIndicatorItems() {
        return [];
    }

    _addLoadingIndicator() {
        // should be implemented in child classes
    }

    _getAdditionalParams(params) {
        // should be implemented in child classes if it necessary
        return params;
    }

    _isValid() {
        return true;
    }

    _onSubmit(e) {
        this._clearMessages();

        if (!this._isValid()) {
            return;
        }

        let params = $H();
        let count = 0;
        this._ids.each(id => {
            params.set(`ids[${count}]`, id);
            count++;
        });
        params = this._getAdditionalParams(params);

        this.disable();
        this._addLoadingIndicator(this._getLoadingIndicatorItems());

        if (this._needFlyEffect()) {
            const beginOffset = Element.cumulativeOffset(e.target);
            getComponent('asyncProgressBarWrapper')
                .fly(
                    beginOffset,
                    this._longtask,
                    () => {
                        this._sendRequest(params);
                    }
                );
        } else {
            this._sendRequest(params);
        }
    }

    _needFlyEffect() {
        return (false !== this._longtask);
    }

    _sendRequest(params) {
        new Ajax.Request(
            this._handlerUrl,
            {
                method: 'post',
                parameters: params,
                onSuccess: this._onSuccess.bind(this),
                onException: this._onException.bind(this),
            }
        );
    }
};

// TODO WPT use Class.create()
ConfirmationPopupManager.PopupForm.subclasses = [];
