// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { Form as BaseForm } from '@plesk/ui-library';
import { createElement, Component } from 'react';
import PropTypes from 'prop-types';
import { redirect, redirectPost } from 'jsw';
import stringify from 'qs/lib/stringify';

class Form extends Component {
    state = {
        errors: {},
        state: null,
    };

    handleSubmit = (values, isApply) => {
        Promise.resolve(typeof this.props.onSubmit === 'function' ? this.props.onSubmit(values) : true)
            .then(result => {
                if (result) {
                    this.setState({
                        state: isApply ? 'apply' : 'submit',
                    });

                    new Ajax.Request(this.props.action || window.location.href, {
                        method: 'post',
                        parameters: stringify(values),
                        onSuccess: this.handleSubmitSuccess,
                    });
                }
            });
    };

    handleSubmitSuccess = ({ responseJSON }) => {
        if (responseJSON.redirect) {
            if (this.state.state === 'apply') {
                document.location.reload();
            } else if (responseJSON.postData) {
                redirectPost(responseJSON.redirect, responseJSON.postData, responseJSON.target);
            } else {
                redirect(responseJSON.redirect, null, responseJSON.target);
            }
            return;
        }

        this.setState({
            state: null,
            errors: responseJSON.formMessages,
        });
    };

    render() {
        return (
            <BaseForm
                {...this.props}
                onSubmit={this.handleSubmit}
                errors={this.state.errors}
                state={this.state.state}
            />
        );
    }
}

Form.propTypes = {
    action: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
};

Form.defaultProps = {
    onSubmit: null,
};

export default Form;
