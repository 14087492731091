// Copyright 1999-2018. Plesk International GmbH. All rights reserved.

import { Component } from './component';

/**
 * @param {Entry} entry
 * @return {Promise}
 */
const readEntry = entry => new Promise(resolve => {
    if (entry.isDirectory) {
        entry.createReader().readEntries(function (entries) {
            resolve(entriesToFiles(entries));
        });
    } else {
        entry.file(resolve);
    }
});

/**
 * @param {String[]} entries
 * @return {Promise}
 */
const entriesToFiles = entries => Promise.all(entries.map(readEntry))
    .then(items => {
        let files = [];
        items.forEach(function (item) {
            if (Array.isArray(item)) {
                files = files.concat(item);
            } else {
                files.push(item);
            }
        });
        return files;
    });

/**
 * @param {File} file
 * @return {Promise}
 */
const isFile = file => new Promise(resolve => {
    if (file.size > 4096) {
        resolve(true);
        return;
    }

    if (!window.FileReader
        || (window.opera && navigator.platform.toLowerCase().indexOf('mac') > -1 && window.opera.version() === '12.00')
    ) {
        resolve(null);
    } else {
        try {
            const reader = new FileReader();
            reader.onerror = () => {
                reader.onloadend = null;
                reader.onprogress = null;
                reader.onerror = null;
                resolve(false);
            };
            reader.onprogress = e => {
                reader.onloadend = null;
                reader.onprogress = null;
                reader.onerror = null;
                if (e.type !== 'loadend') {
                    reader.abort();
                }
                resolve(true);
            };
            reader.onloadend = reader.onprogress;
            reader.readAsDataURL(file);
        } catch (e) {
            resolve(false);
        }
    }
});

/**
 * @param {Event} e
 * @return {Boolean}
 */
const isDragFiles = e => {
    if (!e.dataTransfer) {
        return false;
    }

    if (e.dataTransfer.effectAllowed === 'none') {
        return false;
    }

    return e.dataTransfer.files
        || (!Prototype.Browser.WebKit && e.dataTransfer.types.contains && e.dataTransfer.types.contains('Files'));
};

/**
 * @param {Event} e
 * @return {Promise}
 */
const getDragFiles = e => {
    if (!e.dataTransfer) {
        return Promise.resolve([]);
    }

    if (!e.dataTransfer.items) {
        return Promise.all([].map.call(e.dataTransfer.files, isFile))
            .then(isFiles => [].filter.call(e.dataTransfer.files, (file, index) => isFiles[index]));
    }

    return entriesToFiles(
        [].map.call(e.dataTransfer.items, item => item.webkitGetAsEntry())
            .filter(entry => !!entry)
    );
};

/**
 * Drop area component
 */
export class DropArea extends Component {
    _initConfiguration(config) {
        super._initConfiguration({
            cls: 'fm-drop-area',
            ...config,
        });

        this._onDrop = this._getConfigParam('onDrop');
    }

    _initComponentElement() {
        super._initComponentElement();

        this._componentElement.innerHTML = (
            '<div class="fm-drop-area-wrap">' +
                `<span class="fm-drop-area-text">${this.lmsg('dragAndDropArea')}</span>` +
            '</div>'
        );

        this.hide();
    }

    _addEvents() {
        this._addDocumentHandlers();
        this._addDropAreaHandlers();
    }

    _addDocumentHandlers() {
        document.addEventListener('dragover', e => {
            if (e.dataTransfer) {
                e.preventDefault();
            }
        });

        document.addEventListener('dragenter', e => {
            if (!isDragFiles(e)) {
                return;
            }
            this.show();
        });

        document.addEventListener('drop', e => {
            if (!isDragFiles(e)) {
                return;
            }
            this.hide();
            e.preventDefault();
        });
    }

    _addDropAreaHandlers() {
        this._componentElement.addEventListener('dragover', e => {
            if (!isDragFiles(e)) {
                return;
            }
            e.stopPropagation();
            e.preventDefault();
        });

        this._componentElement.addEventListener('drop', e => {
            if (!isDragFiles(e)) {
                return;
            }
            e.preventDefault();

            this.hide();
            if (this._onDrop) {
                getDragFiles(e).then(this._onDrop);
            }
        });

        this._componentElement.addEventListener('mouseover', () => {
            this.hide();
        });
    }
}
