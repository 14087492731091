// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

const FTYPE_TEXT = 'text';
const FTYPE_IMAGE = 'image';
const FTYPE_AUDIO = 'audio';
const FTYPE_SECURITY = 'security';
const FTYPE_WEBSCRIPT = 'webscript';
const FTYPE_WEBPAGE = 'webpage';
const FTYPE_WAPPAGE = 'wappage';
const FTYPE_COMPRESSED = 'compressed';
const FTYPE_UNKNOWN_TEXT = 'unknown-text';
const FTYPE_UNKNOWN_BINARY = 'unknown-binary';
const FTYPE_ZIP = 'zip';
const FTYPE_RAR = 'rar';
const FTYPE_TAR = 'tar';
const FTYPE_TGZ = 'tgz';

export const getTypeIcon = ({ type, isDirectory, name }) => {
    if (isDirectory) {
        return name === '..'
            ? require('icons/16/plesk/uplevel.png')
            : require('icons/16/plesk/file-folder.png');
    }

    const list = {
        [FTYPE_TEXT]: require('icons/16/plesk/file-txt.png'),
        [FTYPE_IMAGE]: require('icons/16/plesk/file-image.png'),
        [FTYPE_AUDIO]: require('icons/16/plesk/file-audio.png'),
        [FTYPE_SECURITY]: require('icons/16/plesk/file-private.png'),
        [FTYPE_WEBSCRIPT]: require('icons/16/plesk/file-webscript.png'),
        [FTYPE_WEBPAGE]: require('icons/16/plesk/file-html.png'),
        [FTYPE_WAPPAGE]: require('icons/16/plesk/file-html.png'),
        [FTYPE_COMPRESSED]: require('icons/16/plesk/file-arch.png'),
        [FTYPE_UNKNOWN_TEXT]: require('icons/16/plesk/file-txt.png'),
        [FTYPE_UNKNOWN_BINARY]: require('icons/16/plesk/file-binary.png'),
        [FTYPE_ZIP]: require('icons/16/plesk/file-arch.png'),
        [FTYPE_RAR]: require('icons/16/plesk/file-arch.png'),
        [FTYPE_TAR]: require('icons/16/plesk/file-arch.png'),
        [FTYPE_TGZ]: require('icons/16/plesk/file-arch.png'),
    };

    return list[type];
};
