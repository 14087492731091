// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { StatusMessage } from './status-message';
import api from './api';
import { redirectPost } from './form-redirect';
import addStatusMessage from './addStatusMessage';
import clearStatusMessages from './clearStatusMessages';
import prepareUrl from './prepareUrl';
import escapeHtml from './escapeHtml';

export class WebserverConfigurationFailure extends StatusMessage {
    _initConfiguration(config) {
        this._rebuildFailedUrl = '^/admin/subscription/webserver-configuration-rebuild/?returnUrl=/';
        this._rebuildAllUrl = '^/admin/subscription/webserver-configuration-rebuild-all/';

        super._initConfiguration(config);
        this._moduleInstalled = this._getConfigParam('moduleInstalled');
    }

    _renderMessage() {
        return this.lmsg('message', {
            error: escapeHtml(this._message),
            link: `<a id="webserver-configuration-failure-rebuild-failed">${this.lmsg('link')}</a>`,
            'link-all': `<a id="webserver-configuration-failure-rebuild-all">${this.lmsg('link-all')}</a>`,
            'module-link': this._moduleInstalled ? `<a href="/modules/configurations-troubleshooter/">${this.lmsg('moduleLink')}</a>` : '',
        });
    }

    _addEvents() {
        super._addEvents();
        document.getElementById('webserver-configuration-failure-rebuild-failed').addEventListener('click', this._rebuildFailed.bind(this));
        document.getElementById('webserver-configuration-failure-rebuild-all').addEventListener('click', this._rebuildAll.bind(this));
    }

    _rebuildFailed() {
        redirectPost(this._rebuildFailedUrl);
    }

    _rebuildAll() {
        this._onCreateExecute();
        api.post(prepareUrl(this._rebuildAllUrl))
            .then(this._onSuccess.bind(this))
            .catch(this._onException.bind(this));
    }

    _onCreateExecute() {
        clearStatusMessages();
        addStatusMessage('warning', `<span class="ajax-loading">${this.lmsg('messageInProgress')}</span>`);
    }

    _onSuccess(response) {
        clearStatusMessages();
        if ('success' === response.status) {
            addStatusMessage('info', this.lmsg('messageSuccess'));
        } else {
            addStatusMessage('error', this.lmsg('messageFailure') + response.message);
        }
    }

    _onException(exception) {
        clearStatusMessages();

        if (window.location.port === '') {
            // Configuration Troubleshooter restarts main web server, therefore the connection is always interrupted.
            addStatusMessage('info', this.lmsg('messageSuccess'));
        } else {
            addStatusMessage('error', `Internal error: ${exception}`);
        }
    }
}
