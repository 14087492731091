// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

/**
 * Utility class for setting/reading values from browser cookies.
 * Values can be written using the {@link #set} or {@link #setPermanent} method.
 * Values can be read using the {@link #get} method.
 * A cookie can be invalidated on the client machine using the {@link #remove} method.
 */
export default {
    /**
     * Retrieves cookies that are accessible by the current page. If a cookie does not exist, `get()` returns null.
     * @param {String} name The name of the cookie to get
     * @returns {String|null}  Returns the cookie value for the specified name; null if the cookie name does not exist.
     */
    get(name) {
        const cookies = document.cookie.split('; ');
        for (let i = 0; i < cookies.length; i++) {
            const crumbs = cookies[i].split('=');
            if (name === crumbs[0]) {
                return unescape(crumbs[1]);
            }
        }
        return null;
    },

    /**
     * Creates a cookie with the specified name and value.
     * @param {String} name The name of the cookie to set.
     * @param {String} value The value to set for the cookie.
     * @param {String} [expires] Specify an expiration date the cookie is to persist until.
     * @param {String} [path] Setting a path on the cookie restricts access to pages that match that path.
     */
    set(name, value, expires, path) {
        let cookie = `${name}=${escape(value)}; `;

        if (('undefined' !== typeof expires) && expires) {
            cookie += `expires=${expires}; `;
        }

        if (('undefined' !== typeof path) && path) {
            cookie += `path=${path}; `;
        }

        if (window.location.protocol === 'https:') {
            cookie += 'secure; ';
        }

        document.cookie = cookie;
    },

    /**
     * Creates a permanent cookie with the specified name and value.
     * @param {String} name The name of the cookie to set.
     * @param {String} value The value to set for the cookie.
     * @param {String} [path] Setting a path on the cookie restricts access to pages that match that path.
     */
    setPermanent(name, value, path) {
        const date = new Date();
        date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
        const expires = date.toGMTString();
        this.set(name, value, expires, path);
    },

    /**
     * Removes a cookie with the provided name from the browser by setting its expiration date to sometime in the past.
     * @param {String} name
     * @param {String} [path]
     */
    remove(name, path) {
        this.set(name, '', 'Fri, 31 Dec 1999 23:59:59 GMT', path);
    },
};
