// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

import { onReady, redirectPost, showInternalError, BrowserFeatures, keyCode } from 'jsw';
import Cookie from 'common/cookie';

/* eslint-disable camelcase */

export const syn = s => {
    if (!s || !s.options || !s.options.length) {
        return false;
    }

    if (s.options[s.options.length - 1].selected) {
        s.options[s.options.length - 1].selected = false;
    }

    return true;
};

// ---------------------------------- redirect

export const go_to = href => {
    try {
        window.location = href;
    } catch (e) {
    }
};

// ---------------------------------- conhelp & help

export const SetContext = (context, default_conhelp) => {
    // eslint-disable-next-line new-cap
    SetHelpModule('');

    try {
        if (context) {
            top._context = context;
        }
        if (default_conhelp) {
            top._default_conhelp = default_conhelp;
        } else if (context) {
            top._default_conhelp = context;
        }
    } catch (e) {
        return false;
    }

    return true;
};

export const SetHelpPrefix = prefix => {
    try {
        top._help_prefix = prefix;
    } catch (e) {
        return false;
    }

    return true;
};

export const SetHelpModule = module => {
    try {
        top._help_module = module;
    } catch (e) {
        return false;
    }

    return true;
};

export const OpenHelpWindow = (context, prefix, module) => {
    const url = getHelpUrl(context, prefix, module);

    try {
        const w = window.open(url, 'help',
            'toolbar=no,scrollbars=yes,resizable=yes');
        w.focus();
        return true;
    } catch (e) {
        return false;
    }
};

export const getHelpUrl = (context, prefix, module) => {
    if (context === undefined) {
        // eslint-disable-next-line new-cap
        context = GetContext();
    }
    if (prefix === undefined) {
        // eslint-disable-next-line new-cap
        prefix = GetHelpPrefix();
    }
    if (module === undefined) {
        // eslint-disable-next-line new-cap
        module = GetHelpModule();
    }

    if (module !== '') {
        prefix = '';
    }

    let url = `/help.php?context=${escape(context)}`;
    if (prefix !== '') {
        url += `&prefix=${escape(prefix)}`;
    }
    if (module !== '') {
        url += `&module=${escape(module)}`;
    }

    return url;
};

export const GetContext = () => {
    try {
        return top._context;
    } catch (e) {
        return false;
    }
};

export const GetHelpPrefix = () => {
    try {
        if (top._help_prefix === undefined) {
            return '';
        }
        return top._help_prefix;
    } catch (e) {
        return false;
    }
};

export const GetHelpModule = () => {
    try {
        if (top._help_module === undefined) {
            return '';
        }
        return top._help_module;
    } catch (e) {
        return false;
    }
};

/**
 * @deprecated
 */
export const lon = () => true;

/**
 * @deprecated
 */
export const loff = () => true;

export const lsubmit = f => {
    try {
        if (f.lock.value === 'true') {
            return false;
        }
        f.lock.value = 'true';
    } catch (e) {
    }

    lon();

    const button = document.getElementById('buttonid-ok');
    if (button) {
        button.disabled = true;
        button.classList.add('btn-waiting');
    }

    try {
        f.submit();
    } catch (e) {
        loff();
        f.lock.value = 'false';
        return false;
    }
    return true;
};

export const getParentNodeByName = (o, name) => {
    const parent = o.parentNode;
    if (!parent) {
        return false;
    }
    if (parent.nodeName !== name) {
        return getParentNodeByName(parent, name);
    }
    return parent;
};

export const errorfield = (o, status) => {
    const tr = getParentNodeByName(o, 'TR');
    if (!tr) {
        return false;
    }
    tr.className = status ? 'error' : '';
    return true;
};

// global actions and behaviors
onReady(() => {
    document.querySelectorAll('textarea.js-auto-resize').forEach(element => {
        const resizeHandler = () => {
            const lines = element.value.split('\n').length;
            if (lines < originalSize) {
                element.rows = originalSize;
            } else {
                element.rows = lines + 2;
            }
        };

        const originalSize = element.rows;
        resizeHandler();

        if (!Prototype.Browser.IE) {
            // IE incorrectly displays textarea after programmatic change of number of rows
            element.addEventListener('keyup', resizeHandler);
        }
    });

    // disable autofill for forms with disabled autocomplete fields (Chrome issue)
    document.querySelectorAll('form input[autocomplete="off"]').forEach(element => {
        element.closest('form').setAttribute('autocomplete', 'off');
    });
});

// use POST method for links with data-method="post"
document.addEventListener('click', event => {
    const element = event.target.closest('a[data-method="post"]');
    if (element) {
        event.preventDefault();
        redirectPost(element.href);
    }
});

// cross-site request forgery protection for legacy forms in 3d-party extensions
if ('undefined' !== typeof Prototype) {
    const appendForgeryProtection = form => {
        if (!form || (form.method && form.method.toUpperCase() === 'GET') || form.forgery_protection_token) {
            return;
        }
        const forgeryToken = document.getElementById('forgery_protection_token');
        if (!forgeryToken) {
            return;
        }
        form.appendChild(new Element('input', {
            type: 'hidden',
            name: 'forgery_protection_token',
            value: forgeryToken.content,
        }));
    };

    document.addEventListener('submit', ({ target }) => {
        const form = target.closest('form');
        appendForgeryProtection(form);
    });

    const origSubmit = HTMLFormElement.prototype.submit;
    HTMLFormElement.prototype.submit = function () {
        appendForgeryProtection(this);
        return origSubmit.call(this);
    };

    const origOpen = XMLHttpRequest.prototype.open;
    XMLHttpRequest.prototype.open = function (method, url, ...args) {
        origOpen.call(this, method, url, ...args);

        const forgeryToken = document.getElementById('forgery_protection_token');
        if (forgeryToken && (!url.match(/^(?:[a-z]+:)?\/\//) || url.indexOf(window.location.host) !== -1)) {
            this.setRequestHeader('X-Forgery-Protection-Token', forgeryToken.content);
        }
    };

    const origInitialize = Ajax.Request.prototype.initialize;
    Ajax.Request.prototype.initialize = function (url, options) {
        return origInitialize.call(this, url, {
            onException(transport, exception) {
                throw exception;
            },
            on400() {
                // Monkey patching Prototype for proper handling of session expiration
                top.window.location.reload();
            },
            on500(transport) {
                showInternalError(transport.responseText);
            },
            on0() {
                // do nothing in case of internal/network error
                return false;
            },
            ...options,
        });
    };

    Ajax.Request.prototype.abort = function () {
        this.transport.abort();
    };
}

// testing mode support
if ('true' === Cookie.get('debug-testing-mode')) {
    onReady(() => {
        BrowserFeatures.transition = false;
        const css = document.createElement('style');
        css.type = 'text/css';
        css.innerHTML = `*, *:before, *:after {
            transitions-delay: 0s !important;
            transition-duration: 0s !important;
            animation-duration: 0s !important;
        }`;
        document.body.appendChild(css);

        const bottomAnchor = document.getElementById('bottomAnchor');
        if (bottomAnchor) {
            const date = new Date();
            bottomAnchor.innerHTML = date.getTime();
        }
    });
}

// prevent AJAX request canceling on Escape button press in Firefox
document.addEventListener('keydown', event => {
    if (keyCode.ESC === event.keyCode) {
        event.preventDefault();
        event.stopPropagation();
    }
});
