// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { useRef, useEffect } from 'react';

const useEventListener = (event, handler, element) => {
    const handlerRef = useRef();

    useEffect(() => {
        handlerRef.current = handler;
    }, [handler]);

    useEffect(
        () => {
            if (!element || !element.addEventListener) {
                return;
            }

            const listener = event => handlerRef.current(event);

            element.addEventListener(event, listener);

            return () => {
                element.removeEventListener(event, listener);
            };
        },
        [event, element]
    );
};

export default useEventListener;
