// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

export default [
    {
        path: '/admin/setup/progress',
        component: () => import('./views/admin/setup/progress'),
    },
    {
        path: '/admin/home',
        component: () => import('./views/admin/home/admin'),
    },
    {
        path: '/admin/about',
        component: () => import('./views/admin/about/index'),
    },
    {
        path: '/admin/server/tools',
        component: () => import('./views/admin/server/tools'),
    },
    {
        path: ['/admin/help/tutorials', '/smb/help/tutorials'],
        component: () => import('./views/default/help/tutorials'),
    },
    {
        path: ['/smb/file-manager/show', '/smb/file-manager/edit', '/smb/file-manager/code-editor'],
        component: () => import('./views/smb/file-manager'),
    },
    {
        path: '/smb/web/setup',
        component: () => import('./views/smb/web/setup'),
    },
    {
        path: ['/smb/web/view', '/smb/web/overview'],
        component: () => import('./views/smb/web/view'),
        denyClientRedirect: true,
    },
];
